import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["userName", "email"];
var __jsx = React.createElement;
import { Avatar as ChAvatar } from '@chakra-ui/react';
import React from 'react';
import { MD5 } from '../utils/md5';
export function Avatar(_ref) {
  var userName = _ref.userName,
    email = _ref.email,
    props = _objectWithoutProperties(_ref, _excluded);
  // TODO: Add an avatar image URL to user and use that instead of Gravatar.
  var emailHash = email ? MD5(email) : undefined;

  // TODO: Tooltip w/ userName
  return __jsx(ChAvatar, _extends({
    height: "40px",
    width: "40px",
    src: emailHash ? "https://www.gravatar.com/avatar/".concat(emailHash, "?d=mp") : undefined,
    name: userName
  }, props));
}