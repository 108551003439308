import { jsx as _jsx } from "react/jsx-runtime";
import { Menu as ChMenu, MenuButton as ChMenuButton, MenuDivider as ChMenuDivider, MenuGroup as ChMenuGroup, MenuItem as ChMenuItem, MenuItemOption as ChMenuItemOption, MenuList as ChMenuList, MenuOptionGroup as ChMenuOptionGroup, } from '@chakra-ui/menu';
import { forwardRef } from '@chakra-ui/react';
/**
 * Menu component. See [Menu](https://v1.chakra-ui.com/docs/components/overlay/menu) for the complete documentation.
 */
export function Menu(props) {
    return _jsx(ChMenu, { ...props });
}
export const MenuButton = forwardRef((props, ref) => {
    return _jsx(ChMenuButton, { ref: ref, ...props });
});
export const MenuGroup = forwardRef((props, ref) => {
    return _jsx(ChMenuGroup, { ref: ref, ...props });
});
export const MenuItem = forwardRef((props, ref) => {
    return _jsx(ChMenuItem, { ref: ref, ...props });
});
export const MenuItemOption = forwardRef((props, ref) => {
    return _jsx(ChMenuItemOption, { ref: ref, ...props });
});
export const MenuList = forwardRef((props, ref) => {
    return _jsx(ChMenuList, { ref: ref, ...props });
});
export function MenuDivider(props) {
    return _jsx(ChMenuDivider, { ...props });
}
export function MenuOptionGroup(props) {
    return _jsx(ChMenuOptionGroup, { ...props });
}
