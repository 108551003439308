import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useBreakpoint } from '@chakra-ui/react';
import { EmployeeFeatureFlags, UserFeatureFlags } from '@playful/api';
import { Box, MoreIcon, ProBadgeIcon, Text } from '@playful/design_system';
import { humanizePropertyName } from '@playful/runtime';
import RouterDefault from 'next/router';
import React, { useCallback } from 'react';
import { buildUserRoute } from '../paths';
import { ROUTE_ABOUT, ROUTE_BLOG, ROUTE_CAREERS, ROUTE_CONTACT, ROUTE_FORUM, ROUTE_HOME, ROUTE_MY_ACCOUNT,
//ROUTE_PRICING,
ROUTE_SUPPORT, ROUTE_TEMPLATES, ROUTE_TUTORIALS
// ROUTE_WALKTHROUGH,
} from '../routes';
import { useUserContext } from './UserContext';
export var UserLabel = function UserLabel() {
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  return __jsx(Box, {
    py: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    w: "100%"
  }, __jsx(Box, {
    minW: "0",
    mr: 2
  }, __jsx(Box, null, __jsx(Text, {
    fontSize: "md",
    fontWeight: "medium",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }, "@", user.name)), __jsx(Box, null, __jsx(Text, {
    fontSize: "xs",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }, user.email))), hasActiveSubscription && __jsx(Box, {
    textAlign: "center"
  }, __jsx(Box, null, __jsx(ProBadgeIcon, null)), __jsx(Box, null, __jsx(Text, {
    fontSize: "2xs",
    color: "purple.600",
    fontWeight: "bold"
  }, "PRO"))));
};
var MY_HATCH_ROUTES = function MY_HATCH_ROUTES(userName) {
  return {
    label: 'My Hatch',
    items: [{
      label: 'My projects',
      path: ROUTE_HOME
    }, {
      label: 'My public profile',
      path: buildUserRoute(userName)
    }]
  };
};
var HELP_MENU_ITEMS = {
  label: 'Help',
  items: [{
    label: 'Forums',
    url: ROUTE_FORUM
  }, {
    label: 'Customer support',
    url: ROUTE_SUPPORT
  }]
};

/*
const PRICING_MENU_ITEMS: RouteItem = {
  label: 'Plans & Pricing',
  path: ROUTE_PRICING,
};
*/

var ABOUT_MENU_ITEMS = {
  label: 'About',
  items: [{
    label: 'Blog',
    url: ROUTE_BLOG
  }, {
    label: 'Team',
    url: ROUTE_ABOUT
  }, {
    label: 'Careers',
    url: ROUTE_CAREERS
  }, {
    label: 'Contact',
    url: ROUTE_CONTACT
  }]
};
var INSPIRATION_ROUTES = {
  label: 'Getting Started',
  items: [{
    path: ROUTE_TEMPLATES,
    label: 'Templates'
  }, {
    path: ROUTE_TUTORIALS,
    label: 'Tutorials'
  }
  // TODO: Uncomment when Walkthrough is ready
  // { path: ROUTE_WALKTHROUGH, label: 'Walkthrough' },
  ]
};

var USERINFO = {
  label: __jsx(UserLabel, null),
  menuItemProps: {
    _hover: {},
    _active: {}
  }
};
var DIVIDER = {
  type: 'divider'
};
var GetSignOut = function GetSignOut(onSignOutClick) {
  return {
    label: 'sign out',
    onClick: onSignOutClick
  };
};
var navigationRoutes = function navigationRoutes(_ref) {
  var user = _ref.user,
    isLoggedInUser = _ref.isLoggedInUser,
    breakpoint = _ref.breakpoint;
  var routes = [];
  var included = [ABOUT_MENU_ITEMS];
  if (isLoggedInUser) included.push(HELP_MENU_ITEMS);
  if (breakpoint === 'md') {
    routes.push(INSPIRATION_ROUTES, {
      label: __jsx(MoreIcon, null),
      items: included,
      withArrow: false
    });
  } else if (!['base', 'sm', 'xs'].includes(breakpoint)) {
    routes.push.apply(routes, [INSPIRATION_ROUTES].concat(included));
  }
  if (isLoggedInUser) {
    routes.unshift(MY_HATCH_ROUTES(user.name));
  }
  return routes;
};
var getUserRoutes = function getUserRoutes(_ref2) {
  var user = _ref2.user,
    onSignOutClick = _ref2.onSignOutClick,
    hasFlag = _ref2.hasFlag,
    toggleUserFlag = _ref2.toggleUserFlag;
  var isEmployee = user.userType === 'employee';
  var flags = isEmployee ? _objectSpread(_objectSpread({}, UserFeatureFlags), EmployeeFeatureFlags) : UserFeatureFlags;
  var items = [{
    label: 'my account',
    path: ROUTE_MY_ACCOUNT
  }, {
    label: 'tool settings',
    items: Object.keys(flags).map(function (feature) {
      return {
        label: "".concat(hasFlag(feature) ? 'Disable' : 'Enable', " ").concat(humanizePropertyName(feature)),
        onClick: function onClick() {
          return toggleUserFlag(feature);
        }
      };
    })
  }];

  // Easy way to switch between CMS modes
  if (isEmployee) {
    if (RouterDefault.isPreview) {
      items.push({
        label: 'disable draft mode',
        path: '/api/disable-draft'
      });
    } else {
      items.push({
        label: 'enable draft mode',
        path: '/api/draft'
      });
    }
  }
  return items;
};
export var useNavigationLinks = function useNavigationLinks() {
  var _useUserContext2 = useUserContext(),
    user = _useUserContext2.user,
    isLoggedInUser = _useUserContext2.isLoggedInUser,
    signOut = _useUserContext2.signOut,
    hasFlag = _useUserContext2.hasFlag,
    toggleUserFlag = _useUserContext2.toggleUserFlag;
  var breakpoint = useBreakpoint({
    fallback: 'lg'
  });
  var getNavigationRoutes = useCallback(function () {
    return navigationRoutes({
      user: user,
      isLoggedInUser: isLoggedInUser,
      breakpoint: breakpoint
    });
  }, [user, isLoggedInUser, breakpoint]);
  var getUserProfileRoutes = useCallback(function () {
    var userRoutes = getUserRoutes({
      user: user,
      onSignOutClick: signOut,
      hasFlag: hasFlag,
      toggleUserFlag: toggleUserFlag
    });
    return {
      label: '',
      items: [USERINFO, DIVIDER].concat(_toConsumableArray(userRoutes), [DIVIDER, GetSignOut(signOut)])
    };
  }, [hasFlag, signOut, toggleUserFlag, user]);
  var getAllRoutes = useCallback(function () {
    var includeMyHatch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var navigationRoutes = [INSPIRATION_ROUTES,
    //PRICING_MENU_ITEMS,
    HELP_MENU_ITEMS, ABOUT_MENU_ITEMS];
    if (!isLoggedInUser) {
      return {
        label: '',
        items: navigationRoutes
      };
    }
    var userRoutes = getUserRoutes({
      user: user,
      onSignOutClick: signOut,
      hasFlag: hasFlag,
      toggleUserFlag: toggleUserFlag
    });
    var allRoutes = {
      label: '',
      items: [USERINFO, DIVIDER].concat(_toConsumableArray(userRoutes), [DIVIDER], navigationRoutes, [DIVIDER, GetSignOut(signOut)])
    };
    return allRoutes;
  }, [isLoggedInUser, user, signOut, hasFlag, toggleUserFlag]);
  return {
    getUserProfileRoutes: getUserProfileRoutes,
    getNavigationRoutes: getNavigationRoutes,
    getAllRoutes: getAllRoutes
  };
};