import { buildUserRoute } from '../../paths.js';
export var lightBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-light', 'edit'),
  query: {
    fork: true,
    title: 'Untitled'
  }
};
export var darkBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-dark', 'edit'),
  query: {
    fork: true,
    title: 'Untitled'
  }
};